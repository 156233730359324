import React, { useRef, useEffect } from "react";
import { AnimationItem } from "lottie-web";
import { Player } from '@lottiefiles/react-lottie-player';
import { LEVEL_10M, LEVEL_100M, LEVEL_5M, LEVEL_2F, LEVEL_2M, LEVEL_1F } from "../../constants";
import "./Control.scss";

interface IControl {
    isReady: boolean;
    isHidden: boolean;
    currentDirection: number;
    onDirectionChange: (direction: number) => void,
    currentLevel: number,
    onLevelChange: (level: number) => void,
}
const Control = ({ isReady, isHidden, currentDirection, onDirectionChange, currentLevel, onLevelChange }: IControl) => {
    const controlRef = useRef<AnimationItem | null>(null);

    const getDirection = (steps: number) => {
        return (currentDirection + steps + 4) % 4;
    };
    const playSegment = (from: number, to: number) => {
        controlRef.current?.stop();
        controlRef.current?.setSegment(from, to);
        controlRef.current?.stop();
        controlRef.current?.play();
    };

    const isUpDisabled = () => {
        return currentLevel === LEVEL_100M || currentLevel === LEVEL_5M || currentLevel === LEVEL_2F;
    };
    const onUpClick = () => {
        if (isUpDisabled()) {
            return;
        }

        playSegment(77, 111);
        onLevelChange(currentLevel === LEVEL_1F ? currentLevel + 1 : currentLevel - 1);
    };

    const isLeftDisabled = () => {
        return currentLevel === LEVEL_100M;
    };
    const onLeftClick = () => {
        if (isLeftDisabled()) {
            return;
        }

        playSegment(156, 202);
        onDirectionChange(getDirection(1));
    };

    const isRightDisabled = () => {
        return currentLevel === LEVEL_100M;
    };
    const onRightClick = () => {
        if (isRightDisabled()) {
            return;
        }

        playSegment(30, 67);
        onDirectionChange(getDirection(-1));
    };

    const isDownDisabled = () => {
        return currentLevel === LEVEL_10M || currentLevel === LEVEL_2M || currentLevel === LEVEL_1F;
    };
    const onDownClick = () => {
        if (isDownDisabled()) {
            return;
        }

        playSegment(117, 153);
        onLevelChange(currentLevel === LEVEL_2F ? currentLevel - 1 : currentLevel + 1);
    };

    useEffect(() => {
        if (!isReady) {
            return;
        }

        controlRef.current?.setSegment(0, 26);
        controlRef.current?.play();
    }, [isReady]);

    return (
        <div className="control" style={{ display: isHidden ? 'none' : 'block' }}>
            <div className="pulse" />
            <div
                className={`up ${isUpDisabled() ? 'disabled' : ''}`}
                onClick={onUpClick}
            />
            <div
                className={`left ${isLeftDisabled() ? 'disabled' : ''}`}
                onClick={onLeftClick}
            />
            <div
                className={`right ${isRightDisabled() ? 'disabled' : ''}`}
                onClick={onRightClick}
            />
            <div
                className={`down ${isDownDisabled() ? 'disabled' : ''}`}
                onClick={onDownClick}
            />
            <Player
                lottieRef={(ref) => controlRef.current = ref}
                src="/animations/control.json"
                renderer="svg"
                keepLastFrame={true}
            />
        </div>
    );
};

export default Control;