import React from "react";
import { Trans } from "react-i18next";
import { IHouseModel } from "../../houses";
import { formatNumber } from "../../utils";
import HouseInfoButtons from "./HouseInfoButtons";
import "./HouseInfo.scss";

interface IHouseInfo {
    house: IHouseModel;
    currentHouseMode: number,
    onHouseModeChange: (mode: number) => void,
}
const HouseInfo = ({ house, currentHouseMode, onHouseModeChange }: IHouseInfo) => {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

    return (
        <div className={`houseInfo ${isCollapsed ? 'collapsed' : ''}`} onClick={e => e.stopPropagation()}>
            <div className="left">
                <div className="title">
                    <h2><Trans i18nKey="tour.house.title">House</Trans> {house.title}</h2>
                    {house.sold && (
                        <b><Trans i18nKey="tour.house.sold">sold</Trans></b>
                    )}
                    {!house.sold && (
                        <p>{formatNumber(house.price)} $</p>
                    )}
                    {!house.sold && (
                        <span className="arrow" onClick={() => setIsCollapsed(prev => !prev)}>
                            {isCollapsed && (
                                <Trans i18nKey="tour.house.more">More</Trans>
                            )}
                            {!isCollapsed && (
                                <Trans i18nKey="tour.house.hide">Hide</Trans>
                            )}
                        </span>
                    )}
                </div>
                {!house.sold && (
                    <div className="size">
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.size.plot">Plot</Trans>
                            </span>
                            <b>{house.size.plot} m<sup>2</sup></b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.size.house">House</Trans>
                            </span>
                            <b>{house.size.house} m<sup>2</sup></b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.size.prise">Prise</Trans>
                            </span>
                            <b>{house.size.prise} m<sup>2</sup></b>
                        </div>
                    </div>
                )}
                {!house.sold && (
                    <HouseInfoButtons
                        currentHouseMode={currentHouseMode}
                        onHouseModeChange={onHouseModeChange}
                    />
                )}
            </div>
            {!house.sold && (
                <div className="right">
                    <div className="top">
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.size.kitchen">Kitchen</Trans>
                            </span>
                            <b>{house.size.kitchen} m<sup>2</sup></b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.size.livingRoom">Living room</Trans>
                            </span>
                            <b>{house.size.livingRoom} m<sup>2</sup></b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.size.bedroom">Bedroom</Trans>
                            </span>
                            <b>{house.size.bedroom} m<sup>2</sup></b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.size.guestRoom">Guest room</Trans>
                            </span>
                            <b>{house.size.guestRoom} m<sup>2</sup></b>
                        </div>
                    </div>
                    <div className="bottom">
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.pool">Pool</Trans>
                            </span>
                            <b>
                                {house.pool
                                    ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                    : <Trans i18nKey="tour.house.no">no</Trans>}
                            </b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.playground">Playground</Trans>
                            </span>
                            <b>
                                {house.playground
                                    ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                    : <Trans i18nKey="tour.house.no">no</Trans>}
                            </b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.sportsField">Sports field</Trans>
                            </span>
                            <b>
                                {house.sportsField
                                    ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                    : <Trans i18nKey="tour.house.no">no</Trans>}
                            </b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="tour.house.recreationArea">Recreation area</Trans>
                            </span>
                            <b>
                                {house.recreationArea
                                    ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                    : <Trans i18nKey="tour.house.no">no</Trans>}
                            </b>
                        </div>
                    </div>
                </div>
            )}
            {!house.sold && (
                <HouseInfoButtons
                    currentHouseMode={currentHouseMode}
                    onHouseModeChange={onHouseModeChange}
                />
            )}
        </div>
    );
};

export default HouseInfo;