import React from "react";
import { Trans } from "react-i18next";
import { HOUSE_MODE_360, HOUSE_MODE_NONE, HOUSE_MODE_PLOT, HOUSE_MODE_ROOF } from "../../constants";
import "./HouseInfoButtons.scss";

interface IHouseInfo {
    currentHouseMode: number,
    onHouseModeChange: (mode: number) => void,
}
const HouseInfoButtons = ({ currentHouseMode, onHouseModeChange }: IHouseInfo) => {
    const onButtonClick = (e: any, mode: number) => {
        e.preventDefault();
        e.stopPropagation();

        onHouseModeChange(mode);
    };

    return (
        <div className="house-info-buttons">
            <button
                className={`${currentHouseMode === HOUSE_MODE_360 ? 'active' : ''}`}
                onClick={(e) => onButtonClick(
                    e, currentHouseMode === HOUSE_MODE_360 ? HOUSE_MODE_NONE : HOUSE_MODE_360)}
            >
                <Trans i18nKey="tour.house.buttons.tour">360 tour</Trans>
            </button>
            {currentHouseMode !== HOUSE_MODE_NONE && (
                <button
                    className={`${currentHouseMode === HOUSE_MODE_ROOF ? 'active' : ''}`}
                    onClick={(e) => onButtonClick(e,
                        currentHouseMode === HOUSE_MODE_ROOF ? HOUSE_MODE_360 : HOUSE_MODE_ROOF)}
                >
                    {currentHouseMode !== HOUSE_MODE_ROOF && (
                        <Trans i18nKey="tour.house.buttons.roof.remove">Remove the roof</Trans>
                    )}
                    {currentHouseMode === HOUSE_MODE_ROOF && (
                        <Trans i18nKey="tour.house.buttons.roof.return">Return the roof</Trans>
                    )}
                </button>
            )}
            {currentHouseMode !== HOUSE_MODE_NONE && (
                <button
                    className={`${currentHouseMode === HOUSE_MODE_PLOT ? 'active' : ''}`}
                    onClick={(e) => onButtonClick(e, HOUSE_MODE_PLOT)}
                >
                    <Trans i18nKey="tour.house.buttons.walking">Plot walking</Trans>
                </button>
            )}
            {/*<button*/}
            {/*    className={`${currentHouseMode === HOUSE_MODE_INTERIOR ? 'active' : ''}`}*/}
            {/*    onClick={(e) => onButtonClick(e, HOUSE_MODE_INTERIOR)}*/}
            {/*>*/}
            {/*    <Trans i18nKey="tour.house.buttons.interior">Interior tour</Trans>*/}
            {/*</button>*/}
        </div>
    );
};

export default HouseInfoButtons;