import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Lottie from "lottie-web";
import { MODE_VILLAGE, MODE_HOUSES, DIRECTION_SOUTH } from "../../constants";
import { LEVEL_10M, LEVEL_60M, LEVEL_100M, LEVEL_2M, LEVEL_5M, LEVEL_2F } from "../../constants";
import { DIRECTION_WEST, DIRECTION_NORTH } from "../../constants";
import { TIME_MORNING } from "../../constants";
import { HOUSE_MODE_NONE, HOUSE_MODE_360, HOUSE_MODE_ROOF, HOUSE_MODE_PLOT } from "../../constants";
import { PANEL_NONE, PANEL_CITY } from "../../constants";
import { MAIN_VIDEO, MAIN_VIDEO_SLOW, MAIN_VIDEO_MOBILE, MAIN_DIRECTION } from "../../configuration";
import transitions, { Transition, IsTouchDevice } from "../../transitions";
import houses from "../../houses";
import House from "../../components/House/House";
import Control from "../../components/Control/Control";
import ModeSelector from "../../components/ModeSelector/ModeSelector";
import TimeSelector from "../../components/TimeSelector/TimeSelector";
import DirectionSelector from "../../components/DirectionSelector/DirectionSelector";
import LevelSelector from "../../components/LevelSelector/LevelSelector";
import "./Tour.scss";

interface ITour {
    isReady: boolean;
}
const Tour = ({ isReady }: ITour) => {
    const [isBlocked, setIsBlocked] = useState<boolean>(true);

    const panelRef = useRef<HTMLDivElement>(null);
    const [currentPanel, setCurrentPanel] = useState<number>(PANEL_NONE);

    const [currentMode, setCurrentMode] = useState<number>(MODE_VILLAGE);
    const [currentLevel, setCurrentLevel] = useState<number>(LEVEL_10M);

    const [currentDirection, setCurrentDirection] = useState<number>(MAIN_DIRECTION);
    const [currentTime, setCurrentTime] = useState<number>(TIME_MORNING);

    const [currentHouseMode, setCurrentHouseMode] = useState<number>(HOUSE_MODE_NONE);
    const [currentHouse, setCurrentHouse] = useState<number>(0);

    const getMainVideoSrc = () => {
        const speed = localStorage.getItem('speed');
        return IsTouchDevice() ? MAIN_VIDEO_MOBILE : speed !== 'fast' ? MAIN_VIDEO_SLOW : MAIN_VIDEO;
    };
    const findShortestPath = (number: number, start: number, end: number, allowCrossZero = true, onlyForward = false) => {
        let forwardPath = [];
        let backwardPath = [];

        // Calculate front path;
        let forwardIndex = start;

        while (forwardIndex !== end) {
            let nextIndex = (forwardIndex + 1) % number;

            if ((!allowCrossZero && !onlyForward) && nextIndex === 0 && forwardIndex !== 0) {
                break;
            }

            forwardPath.push([forwardIndex, nextIndex]);
            forwardIndex = nextIndex;
        }

        // If forward path is not ready clear it;
        if (!allowCrossZero && forwardIndex !== end) {
            forwardPath = [];
        }

        if (onlyForward) {
            return forwardPath;
        }

        // Calculate backward path;
        let backwardIndex = start;

        while (backwardIndex !== end) {
            let nextIndex = (backwardIndex - 1 + number) % number;

            if (!allowCrossZero && backwardIndex === 0 && nextIndex !== 0) {
                break;
            }

            backwardPath.push([backwardIndex, nextIndex]);
            backwardIndex = nextIndex;
        }

        // If backward path is not ready clear it;
        if (!allowCrossZero && backwardIndex !== end) {
            backwardPath = []; // Очищаем backwardPath
        }

        // If one of the paths is empty return another;
        if (forwardPath.length === 0 && backwardPath.length > 0) {
            return backwardPath;
        }

        if (backwardPath.length === 0 && forwardPath.length > 0) {
            return forwardPath;
        }

        // Return shortest path or front path if equal;
        return forwardPath.length <= backwardPath.length ? forwardPath : backwardPath;
    };

    const playTransitions = async (transitions: Transition[], speed = 0) => {
        const videoElements = document.querySelectorAll('video');

        const currentVideo = videoElements[0].style.display === 'none' ? videoElements[1] : videoElements[0];
        const nextVideo = videoElements[0].style.display === 'none' ? videoElements[0] : videoElements[1];

        currentVideo.autoplay = false;
        currentVideo.loop = false;
        currentVideo.pause();

        nextVideo.autoplay = false;
        nextVideo.loop = false;
        nextVideo.pause();

        for (let i = 0; i < transitions.length; i++) {
            await new Promise<void>((resolve, reject) => {
                const currentVideo = videoElements[0].style.display === 'none' ? videoElements[1] : videoElements[0];
                const nextVideo = videoElements[0].style.display === 'none' ? videoElements[0] : videoElements[1];

                nextVideo.src = transitions[i].getSource();
                nextVideo.onended = () => {
                    nextVideo.onended = null;
                    resolve();
                };

                nextVideo.playbackRate = speed ? speed : transitions.length;
                nextVideo.play().then(() => {
                    nextVideo.style.display = 'block';
                    setTimeout(() => currentVideo.style.display = 'none', 50);
                });
            });
        }
    };
    const playLoop = () => {
        const videoElements = document.querySelectorAll('video');

        const currentVideo = videoElements[0].style.display === 'none' ? videoElements[1] : videoElements[0];
        const nextVideo = videoElements[0].style.display === 'none' ? videoElements[0] : videoElements[1];

        currentVideo.loop = false;
        currentVideo.pause();

        nextVideo.loop = false;
        nextVideo.pause();

        nextVideo.src = transitions.find(p =>
            p.mode.from === currentMode
            && p.mode.to === currentMode
            && p.direction.from === currentDirection
            && p.direction.to === currentDirection
            && p.level.from === currentLevel
            && p.level.to === currentLevel
            && p.house.from === currentHouse
            && p.house.to === currentHouse
            && p.houseMode.from === currentHouseMode
            && p.houseMode.to === currentHouseMode
            && p.time.from === currentTime
            && p.time.to === currentTime
        )!.getSource();

        nextVideo.playbackRate = 1;
        nextVideo.loop = true;

        nextVideo.play().then(() => {
            nextVideo.style.display = 'block';
            setTimeout(() => currentVideo.style.display = 'none', 50);
        });
    };

    const onModeChange = async (mode: number) => {
        if (isBlocked) {
            return;
        }

        setCurrentMode(mode);

        let level = currentLevel;
        let direction = currentDirection;

        if (mode === MODE_HOUSES) {
            if (currentTime !== TIME_MORNING) {
                await onTimeChange(TIME_MORNING);
            }

            setCurrentDirection(DIRECTION_WEST);
            setCurrentLevel(LEVEL_10M);
            setCurrentHouse(1);
        } else {
            if (currentHouseMode === HOUSE_MODE_ROOF) {
                if (currentLevel !== LEVEL_2F) {
                    await onLevelChange(LEVEL_2F);
                }

                level = LEVEL_2F;
            }

            if (currentHouseMode === HOUSE_MODE_360) {
                if (currentLevel !== LEVEL_5M) {
                    await onLevelChange(LEVEL_5M);
                }

                level = LEVEL_5M;
            }

            setCurrentDirection(DIRECTION_WEST);
            setCurrentLevel(LEVEL_10M);
            setCurrentHouse(0);
            setCurrentHouseMode(HOUSE_MODE_NONE);
        }

        setIsBlocked(true);
        const transition = transitions.find(p =>
            p.mode.from === currentMode
            && p.mode.to === mode
            && p.direction.from === direction
            && p.direction.to === direction
            && p.level.from === level
            && p.level.to === level
            && p.house.from === currentHouse
            && p.house.to === currentHouse
            && p.houseMode.from === HOUSE_MODE_NONE
            && p.houseMode.to === HOUSE_MODE_NONE
            && p.time.from === TIME_MORNING
            && p.time.to === TIME_MORNING
        );

        if (transition) {
            await playTransitions([transition]);
        }

        setIsBlocked(false);
    };
    const onLevelChange = async (level: number, current: number = currentLevel, houseMode: number = currentHouseMode) => {
        if (isBlocked) {
            return;
        }

        setCurrentLevel(level);

        if (currentLevel === LEVEL_100M && currentTime !== TIME_MORNING) {
            await onTimeChange(TIME_MORNING);
        }

        setIsBlocked(true);

        const selectedTransitions: any[] = [];
        const transition = transitions.find(p1 =>
            p1.mode.from === currentMode
            && p1.mode.to === currentMode
            && p1.direction.from === currentDirection
            && p1.direction.to === currentDirection
            && p1.level.from === currentLevel
            && p1.level.to === level
            && p1.house.from === currentHouse
            && p1.house.to === currentHouse
            && p1.houseMode.from === houseMode
            && p1.houseMode.to === houseMode
            && p1.time.from === TIME_MORNING
            && p1.time.to === TIME_MORNING
        );

        if (!transition) {
            let path = [[current, level]];

            if (currentMode === MODE_VILLAGE) {
                path = findShortestPath(3, current, level, false);
            }

            path.forEach(p => {
                selectedTransitions.push(transitions.find(p1 =>
                    p1.mode.from === currentMode
                    && p1.mode.to === currentMode
                    && p1.direction.from === currentDirection
                    && p1.direction.to === currentDirection
                    && p1.level.from === p[0]
                    && p1.level.to === p[1]
                    && p1.house.from === currentHouse
                    && p1.house.to === currentHouse
                    && p1.houseMode.from === houseMode
                    && p1.houseMode.to === houseMode
                    && p1.time.from === TIME_MORNING
                    && p1.time.to === TIME_MORNING
                ));
            });
        } else {
            selectedTransitions.push(transition);
        }

        await playTransitions(selectedTransitions);
        setIsBlocked(false);
    };

    const onDirectionChange = async (direction: number, level: number = currentLevel, houseMode: number = currentHouseMode) => {
        if (isBlocked) {
            return;
        }

        setIsBlocked(true);
        setCurrentDirection(direction);

        const selectedTransitions: any[] = [];
        const transition = transitions.find(p1 =>
            p1.mode.from === currentMode
            && p1.mode.to === currentMode
            && p1.direction.from === currentDirection
            && p1.direction.to === direction
            && p1.level.from === currentLevel
            && p1.level.to === level
            && p1.house.from === currentHouse
            && p1.house.to === currentHouse
            && p1.houseMode.from === houseMode
            && p1.houseMode.to === houseMode
            && p1.time.from === TIME_MORNING
            && p1.time.to === TIME_MORNING
        );

        if (!transition) {
            const path = findShortestPath(4, currentDirection, direction, true);

            path.forEach(p => {
                selectedTransitions.push(transitions.find(p1 =>
                    p1.mode.from === currentMode
                    && p1.mode.to === currentMode
                    && p1.direction.from === p[0]
                    && p1.direction.to === p[1]
                    && p1.level.from === level
                    && p1.level.to === level
                    && p1.house.from === currentHouse
                    && p1.house.to === currentHouse
                    && p1.houseMode.from === houseMode
                    && p1.houseMode.to === houseMode
                    && p1.time.from === TIME_MORNING
                    && p1.time.to === TIME_MORNING
                ));
            });
        } else {
            selectedTransitions.push(transition);
        }

        await playTransitions(selectedTransitions);
        setIsBlocked(false);
    };
    const onTimeChange = async (time: number) => {
        if (isBlocked) {
            return;
        }

        setIsBlocked(true);
        setCurrentTime(time);

        const selectedTransitions: any[] = [];
        const transition = transitions.find(p1 =>
            p1.mode.from === MODE_VILLAGE
            && p1.mode.to === MODE_VILLAGE
            && p1.direction.from === currentDirection
            && p1.direction.to === currentDirection
            && p1.level.from === LEVEL_100M
            && p1.level.to === LEVEL_100M
            && p1.house.from === 0
            && p1.house.to === 0
            && p1.houseMode.from === HOUSE_MODE_NONE
            && p1.houseMode.to === HOUSE_MODE_NONE
            && p1.time.from === currentTime
            && p1.time.to === time
        );

        if (!transition) {
            const path = findShortestPath(4, currentTime, time, true, true);

            path.forEach(p => {
                selectedTransitions.push(transitions.find(p1 =>
                    p1.mode.from === MODE_VILLAGE
                    && p1.mode.to === MODE_VILLAGE
                    && p1.direction.from === currentDirection
                    && p1.direction.to === currentDirection
                    && p1.level.from === LEVEL_100M
                    && p1.level.to === LEVEL_100M
                    && p1.house.from === 0
                    && p1.house.to === 0
                    && p1.houseMode.from === HOUSE_MODE_NONE
                    && p1.houseMode.to === HOUSE_MODE_NONE
                    && p1.time.from === p[0]
                    && p1.time.to === p[1]
                ));
            });
        } else {
            selectedTransitions.push(transition);
        }

        await playTransitions(selectedTransitions);
        setIsBlocked(false);
    };

    const onHouseChange = async (house: number) => {
        if (isBlocked) {
            return;
        }

        setCurrentHouse(house);

        if (currentHouseMode !== HOUSE_MODE_NONE) {
            await onHouseModeChange(HOUSE_MODE_NONE);
        }

        if (currentHouse === house) {
            return;
        }

        setIsBlocked(true);

        const selectedTransitions: any[] = [];
        const transition = transitions.find(p1 =>
            p1.mode.from === MODE_HOUSES
            && p1.mode.to === MODE_HOUSES
            && p1.direction.from === DIRECTION_WEST
            && p1.direction.to === DIRECTION_WEST
            && p1.level.from === LEVEL_10M
            && p1.level.to === LEVEL_10M
            && p1.house.from === currentHouse
            && p1.house.to === house
            && p1.houseMode.from === HOUSE_MODE_NONE
            && p1.houseMode.to === HOUSE_MODE_NONE
            && p1.time.from === TIME_MORNING
            && p1.time.to === TIME_MORNING
        );

        if (!transition) {
            const path = findShortestPath(9, currentHouse, house, false);

            path.forEach(p => {
                selectedTransitions.push(transitions.find(p1 =>
                    p1.mode.from === MODE_HOUSES
                    && p1.mode.to === MODE_HOUSES
                    && p1.direction.from === DIRECTION_WEST
                    && p1.direction.to === DIRECTION_WEST
                    && p1.level.from === LEVEL_10M
                    && p1.level.to === LEVEL_10M
                    && p1.house.from === p[0]
                    && p1.house.to === p[1]
                    && p1.houseMode.from === HOUSE_MODE_NONE
                    && p1.houseMode.to === HOUSE_MODE_NONE
                    && p1.time.from === TIME_MORNING
                    && p1.time.to === TIME_MORNING
                ));
            });
        } else {
            selectedTransitions.push(transition);
        }

        await playTransitions(selectedTransitions);
        setIsBlocked(false);
    };
    const onHouseModeChange = async (mode: number) => {
        if (isBlocked) {
            return;
        }

        const selectedTransitions = [];

        let houseMode = currentHouseMode;
        let direction = DIRECTION_WEST;
        let level = LEVEL_10M;

        if (mode === HOUSE_MODE_NONE) {
            if (houseMode === HOUSE_MODE_360) {
                if (currentLevel !== LEVEL_5M) {
                    await onLevelChange(LEVEL_5M);
                }

                selectedTransitions.push(...transitions.filter(p =>
                    p.houseMode.from === HOUSE_MODE_360
                    && p.houseMode.to === HOUSE_MODE_NONE
                    && p.direction.from === currentDirection
                    && p.direction.to === DIRECTION_WEST
                    && p.level.from === LEVEL_5M
                    && p.level.to === LEVEL_10M
                ));
            }

            if (houseMode === HOUSE_MODE_ROOF) {
                // await onHouseModeChange(HOUSE_MODE_360);
                if (currentLevel !== LEVEL_2F) {
                    await onLevelChange(LEVEL_2F);
                }

                // if (currentDirection !== DIRECTION_WEST) {
                //     await onDirectionChange(DIRECTION_WEST, LEVEL_2F);
                // }

                const transition = transitions.find(p =>
                    p.houseMode.from === HOUSE_MODE_ROOF
                    && p.houseMode.to === HOUSE_MODE_NONE
                    && p.direction.from === currentDirection
                    && p.direction.to === currentDirection
                );

                if (transition) {
                    selectedTransitions.push(transition);
                }
            }

            setCurrentDirection(DIRECTION_WEST);
            setCurrentLevel(LEVEL_10M);
        }

        if (mode === HOUSE_MODE_360) {
            if (houseMode === HOUSE_MODE_ROOF) {
                if (currentLevel !== LEVEL_2F) {
                    await onLevelChange(LEVEL_2F);
                }

                direction = currentDirection;
                level = LEVEL_2F;

                setCurrentLevel(LEVEL_5M);
            }

            if (houseMode === HOUSE_MODE_NONE) {
                setCurrentLevel(LEVEL_5M);
            }
        }

        if (mode === HOUSE_MODE_ROOF) {
            if (houseMode === HOUSE_MODE_360 && currentLevel !== LEVEL_5M) {
                await onLevelChange(LEVEL_5M);
            }

            if (houseMode === HOUSE_MODE_NONE) {
                await onHouseModeChange(HOUSE_MODE_360);
                await onLevelChange(LEVEL_5M, LEVEL_2M, HOUSE_MODE_360);
            }

            houseMode = HOUSE_MODE_360;
            direction = currentDirection;
            level = LEVEL_5M;

            setCurrentLevel(LEVEL_2F);
        }

        if (mode === HOUSE_MODE_PLOT) {
            level = currentLevel;

            if (houseMode === HOUSE_MODE_ROOF && currentLevel !== LEVEL_2F) {
                await onLevelChange(LEVEL_2F);
                level = LEVEL_2F;
            }

            if (houseMode === HOUSE_MODE_360 && currentLevel !== LEVEL_5M) {
                await onLevelChange(LEVEL_5M);
                level = LEVEL_5M;
            }

            // Select transition to plot walking;
            direction = currentDirection;
            selectedTransitions.push(...transitions.filter(p =>
                p.houseMode.from === houseMode && p.houseMode.to === HOUSE_MODE_PLOT && p.direction.from === direction && p.level.from === level));

            // Select plot transitions;
            level = LEVEL_5M;
            selectedTransitions.push(...transitions.filter(p =>
                p.houseMode.from === HOUSE_MODE_PLOT && p.direction.from === direction && p.level.from === level));
        }

        setIsBlocked(true);
        setCurrentHouseMode(mode);

        if (selectedTransitions.length === 0) {
            const transition = transitions.find(p1 =>
                p1.mode.from === currentMode
                && p1.mode.to === currentMode
                && p1.direction.from === direction
                && p1.direction.to === direction
                && p1.level.from === level
                && p1.level.to === level
                && p1.house.from === currentHouse
                && p1.house.to === currentHouse
                && p1.houseMode.from === houseMode
                && p1.houseMode.to === mode
                && p1.time.from === TIME_MORNING
                && p1.time.to === TIME_MORNING
            );

            if (transition) {
                selectedTransitions.push(transition);
            }
        }

        if (selectedTransitions) {
            await playTransitions(selectedTransitions, 1);
        }

        if (mode === HOUSE_MODE_PLOT) {
            setCurrentDirection(currentDirection === DIRECTION_WEST || currentDirection === DIRECTION_SOUTH ? DIRECTION_NORTH : DIRECTION_WEST);
            setCurrentLevel(LEVEL_5M);
            setCurrentHouseMode(HOUSE_MODE_360);
        }

        setIsBlocked(false);
    };

    useEffect(() => {
        if (currentMode === MODE_VILLAGE && currentLevel === LEVEL_60M && currentDirection === DIRECTION_NORTH) {
            if (currentPanel !== PANEL_NONE) {
                return;
            }

            panelRef.current!.innerHTML = '';
            Lottie.loadAnimation({
                container: panelRef.current! as Element,
                path: '/animations/panel-city.json',
                loop: false,
            });

            setCurrentPanel(PANEL_CITY);
            return;
        }

        if (currentPanel !== PANEL_NONE) {
            panelRef.current!.style.opacity = '0';

            setTimeout(() => {
                setCurrentPanel(PANEL_NONE);
                panelRef.current!.style.opacity = '1';
            }, 1000);

            return;
        }
    }, [currentMode, currentLevel, currentDirection, currentPanel]);

    useEffect(() => {
        if (isBlocked) {
            return;
        }

        playLoop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBlocked]);
    useEffect(() => {
        if (!isReady) {
            return;
        }

        const videoElements = document.querySelectorAll('video');

        videoElements[0].play();
        videoElements[0].onended = () => setIsBlocked(false);
    }, [isReady]);

    return (
        <div id="tour" className={`tour ${isBlocked ? 'blocked' : ''}`}>
            <div ref={panelRef} className={clsx('panel', currentPanel === PANEL_CITY ? 'city' : '')} />
            <video id="firstVideo" src={getMainVideoSrc()} preload="auto" muted playsInline />
            <video id="secondVideo" preload="auto" muted playsInline />
            <Control
                isReady={isReady}
                isHidden={!(currentMode === MODE_VILLAGE || (currentMode === MODE_HOUSES && currentHouseMode !== HOUSE_MODE_NONE))}
                currentDirection={currentDirection}
                onDirectionChange={onDirectionChange}
                currentLevel={currentLevel}
                onLevelChange={onLevelChange}
            />
            <div className="menu">
                <div className="summary">
                    <ModeSelector currentMode={currentMode} onModeChange={onModeChange} />
                    {(currentMode === MODE_VILLAGE || (currentMode === MODE_HOUSES
                        && (currentHouseMode === HOUSE_MODE_360 || currentHouseMode === HOUSE_MODE_ROOF))) && (
                            <LevelSelector
                                currentMode={currentMode}
                                currentHouseMode={currentHouseMode}
                                currentLevel={currentLevel}
                                onLevelChange={onLevelChange}
                            />
                    )}
                    {((currentMode === MODE_VILLAGE && currentLevel !== LEVEL_100M) || (currentMode === MODE_HOUSES
                        && (currentHouseMode === HOUSE_MODE_360 || currentHouseMode === HOUSE_MODE_ROOF))) && (
                            <DirectionSelector
                                currentDirection={currentDirection}
                                onDirectionChange={onDirectionChange}
                            />
                    )}
                    {currentMode === MODE_VILLAGE && currentLevel === LEVEL_100M && (
                        <TimeSelector currentTime={currentTime} onTimeChange={onTimeChange} />
                    )}
                </div>
                {currentMode === MODE_HOUSES && (
                    <div className="houses">
                        {houses.map((p, index) => (
                            <House
                                key={index}
                                index={index}
                                currentHouse={currentHouse}
                                house={p}
                                onHouseChange={onHouseChange}
                                currentHouseMode={currentHouseMode}
                                onHouseModeChange={onHouseModeChange}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tour;