import React from "react";
import {
    LEVEL_10M,
    LEVEL_60M,
    LEVEL_100M,
    MODE_VILLAGE,
    MODE_HOUSES,
    LEVEL_2M,
    LEVEL_5M,
    HOUSE_MODE_360,
    HOUSE_MODE_ROOF,
    LEVEL_1F,
    LEVEL_2F
} from "../../constants";

interface ILevelSelector {
    currentMode: number;
    currentHouseMode: number;
    currentLevel: number,
    onLevelChange: (level: number) => void
}
const LevelSelector = ({ currentMode, currentHouseMode, currentLevel, onLevelChange }: ILevelSelector) => {
    return (
        <>
            {currentMode === MODE_VILLAGE && (
                <div className="submenu submenu-level">
                    <div
                        className={currentLevel === LEVEL_100M ? 'active' : ''}
                        onClick={() => onLevelChange(LEVEL_100M)}
                    >
                        100m
                    </div>
                    <div
                        className={currentLevel === LEVEL_60M ? 'active' : ''}
                        onClick={() => onLevelChange(LEVEL_60M)}
                    >
                        60m
                    </div>
                    <div
                        className={currentLevel === LEVEL_10M ? 'active' : ''}
                        onClick={() => onLevelChange(LEVEL_10M)}
                    >
                        10m
                    </div>
                </div>
            )}
            {currentMode === MODE_HOUSES && currentHouseMode === HOUSE_MODE_360 && (
                <div className="submenu submenu-level">
                    <div
                        className={currentLevel === LEVEL_2M ? 'active' : ''}
                        onClick={() => onLevelChange(LEVEL_2M)}
                    >
                        6m
                    </div>
                    <div
                        className={currentLevel === LEVEL_5M ? 'active' : ''}
                        onClick={() => onLevelChange(LEVEL_5M)}
                    >
                        12m
                    </div>
                </div>
            )}
            {currentMode === MODE_HOUSES && currentHouseMode === HOUSE_MODE_ROOF && (
                <div className="submenu submenu-level">
                    <div
                        className={currentLevel === LEVEL_1F ? 'active' : ''}
                        onClick={() => onLevelChange(LEVEL_1F)}
                    >
                        1F
                    </div>
                    <div
                        className={currentLevel === LEVEL_2F ? 'active' : ''}
                        onClick={() => onLevelChange(LEVEL_2F)}
                    >
                        2F
                    </div>
                </div>
            )}
        </>
    );
};

export default LevelSelector;