import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import Lottie from "lottie-web";
import { useTranslation } from "react-i18next";
import Header from "./containers/Header/Header";
import Tour from "./containers/Tour/Tour";
import AiChat from "./containers/AiChat/AiChat";
import Cookie from "./containers/Cookie/Cookie";
import { SPEED_TEST_IMG_SRC, SPEED_TEST_IMG_SIZE } from "./configuration";
import './App.scss';

const App = () => {
    const loaderRef = useRef(null);
    const [loaderState, setLoaderState] = useState(0);

    const { i18n } = useTranslation();
    const onChangeLanguage = (language: string) => i18n.changeLanguage(language);

    useEffect(() => {
        const currentTime = Date.now();
        const animation = Lottie.loadAnimation({
            container: loaderRef.current! as Element,
            path: `/animations/preloader.json?time=${currentTime}`,
            loop: false,
        });

        animation.addEventListener('complete', () => {
            setLoaderState(1);

            setTimeout(() => {
                setLoaderState(2);
                document.body.className = '';
            }, 1000);
        });
    }, []);
    useEffect(() => {
        const startTime = (new Date()).getTime();
        const download = new Image();

        download.src = SPEED_TEST_IMG_SRC + "?nnn=" + startTime;
        download.onload = () => {
            const endTime = (new Date()).getTime();
            const duration = (endTime - startTime) / 1000;

            const bitsLoaded = SPEED_TEST_IMG_SIZE * 8;
            const speed = ((bitsLoaded / duration) / 1024 / 1024).toFixed(2);

            console.log(`Speed test: ${speed}`);
            localStorage.setItem('speed', +speed < 20 ? 'slow' : 'fast');
        };
    }, []);

    return (
        <div className="app">
            <div id="loader" ref={loaderRef} className={clsx(
                loaderState > 0 ? 'opacity' : '',
                loaderState > 1 ? 'hidden' : '',
            )} />
            <Header isReady={loaderState === 2} onChangeLanguage={onChangeLanguage} />
            <Tour isReady={loaderState === 2} />
            <Cookie/>
            <AiChat chatUrl="https://chat.50k.io" projectId="ecf969ae-4788-4f7c-b8bf-526b369467f1"/>
        </div>
    );
};

export default App;
