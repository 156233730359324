import React from "react";
import { Trans } from "react-i18next";
import { IHouseModel } from "../../houses";
import HouseInfo from "./HouseInfo";
import "./House.scss";

interface IHouse {
    index: number;
    currentHouse: number;
    house: IHouseModel;
    onHouseChange: (house: number) => void,
    currentHouseMode: number,
    onHouseModeChange: (mode: number) => void,
}
const House = ({ index, currentHouse, house, onHouseChange, currentHouseMode, onHouseModeChange }: IHouse) => {
    return (
        <>
            {currentHouse === index && (
                <HouseInfo
                    house={house}
                    currentHouseMode={currentHouseMode}
                    onHouseModeChange={onHouseModeChange}
                />
            )}
            <div
                className={`house ${house.sold ? 'sold' : 'sale'} ${currentHouse === index ? 'active' : ''}`}
                onClick={() => onHouseChange(index)}
            >
                <p>
                    <b><Trans i18nKey="tour.house.title">House</Trans></b> {house.title}
                </p>
                <span>
                    {house.sold && <Trans i18nKey="tour.house.sold">sold</Trans>}
                    {!house.sold && <Trans i18nKey="tour.house.sale">sale</Trans>}
                </span>
            </div>
        </>
    );
};

export default House;