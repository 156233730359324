import { MODE_HOUSES, MODE_VILLAGE } from "./constants";
import { DIRECTION_EAST, DIRECTION_SOUTH, DIRECTION_WEST, DIRECTION_NORTH } from "./constants";
import { LEVEL_10M, LEVEL_60M, LEVEL_100M, LEVEL_2M, LEVEL_5M, LEVEL_2F, LEVEL_1F } from "./constants";
import { TIME_AFTERNOON, TIME_EVENING, TIME_MORNING, TIME_NIGHT } from "./constants";
import { HOUSE_MODE_NONE, HOUSE_MODE_360, HOUSE_MODE_ROOF, HOUSE_MODE_PLOT } from "./constants";

export const IsTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

class TransitionItem {
    from: number = 0;
    to: number = 0;

    constructor(from: number, to: number) {
        this.from = from;
        this.to = to;
    }
}
export class Transition {
    mode: TransitionItem = { from: MODE_VILLAGE, to: MODE_VILLAGE };
    level: TransitionItem = { from: LEVEL_10M, to: LEVEL_10M };
    direction: TransitionItem = { from: DIRECTION_WEST, to: DIRECTION_WEST };
    house: TransitionItem = { from: 0, to: 0 };
    houseMode: TransitionItem = { from: HOUSE_MODE_NONE, to: HOUSE_MODE_NONE };
    time: TransitionItem = { from: TIME_MORNING, to: TIME_MORNING };
    src: string = "";

    constructor(
        mode: TransitionItem,
        level: TransitionItem,
        direction: TransitionItem,
        house: TransitionItem,
        houseMode: TransitionItem,
        time: TransitionItem,
        src: string
    ) {
        this.mode = mode;
        this.level = level;
        this.direction = direction;
        this.house = house;
        this.houseMode = houseMode;
        this.time = time;
        this.src = src;
    }

    getSource: () => string = () => {
        const speed = localStorage.getItem('speed');
        return 'https://50k-demo.fra1.cdn.digitaloceanspaces.com/' + (IsTouchDevice() ? 'mobile/' : speed !== 'fast' ? '2560×1440/' : '') + this.src;
    }
}

const loops10M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1n.mp4"
    ),
];
const directions10M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1es.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1se.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1sw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1ws.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1en.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1ne.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1nw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1wn.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1we.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1ew.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1ns.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1sn.mp4"
    ),
];
const levels10M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_60M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1-2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_60M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1-2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_60M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_60M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/1-2n.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1-3w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1n-3w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1-3e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/1s-3e.mp4"
    ),
];

const loops60M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2n.mp4"
    ),
];
const directions60M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2es.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2se.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2sw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2ws.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2en.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2ne.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2nw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2wn.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/2we.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/2ew.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/2ns.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/2sn.mp4"
    ),
];
const levels60M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2-1e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2-1s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2-1n.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2-3e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2s-3e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2-3w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_60M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/2n-3w.mp4"
    ),
];

const loops100M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "lightchanging/morning.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "lightchanging/morning.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "lightchanging/morning_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "lightchanging/morning_e.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_AFTERNOON),
        "lightchanging/afternoon.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_AFTERNOON),
        "lightchanging/afternoon.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_AFTERNOON),
        "lightchanging/afternoon_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_AFTERNOON),
        "lightchanging/afternoon_e.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_EVENING),
        "lightchanging/evening.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_EVENING),
        "lightchanging/evening.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_EVENING),
        "lightchanging/evening_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_EVENING),
        "lightchanging/evening_e.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_NIGHT),
        "lightchanging/night.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_NIGHT),
        "lightchanging/night.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_NIGHT),
        "lightchanging/night_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_NIGHT),
        "lightchanging/night_e.mp4"
    ),
];
const time100M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_AFTERNOON),
        "lightchanging/morning-afternoon.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_EVENING),
        "lightchanging/morning-evening.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_NIGHT),
        "lightchanging/morning-night.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_EVENING),
        "lightchanging/afternoon-evening.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_NIGHT),
        "lightchanging/evening-night.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_MORNING),
        "lightchanging/night-morning.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_AFTERNOON),
        "lightchanging/morning-afternoon.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_EVENING),
        "lightchanging/morning-evening.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_NIGHT),
        "lightchanging/morning-night.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_EVENING),
        "lightchanging/afternoon-evening.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_NIGHT),
        "lightchanging/evening-night.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_MORNING),
        "lightchanging/night-morning.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_AFTERNOON),
        "lightchanging/morning-afternoon_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_EVENING),
        "lightchanging/morning-evening_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_NIGHT),
        "lightchanging/morning-night_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_EVENING),
        "lightchanging/afternoon-evening_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_NIGHT),
        "lightchanging/evening-night_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_MORNING),
        "lightchanging/night-morning_e.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_AFTERNOON),
        "lightchanging/morning-afternoon_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_EVENING),
        "lightchanging/morning-evening_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_NIGHT),
        "lightchanging/morning-night_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_AFTERNOON, TIME_EVENING),
        "lightchanging/afternoon-evening_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_EVENING, TIME_NIGHT),
        "lightchanging/evening-night_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_NIGHT, TIME_MORNING),
        "lightchanging/night-morning_e.mp4"
    ),
];
const levels100M = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_60M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/3-2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_60M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/3e-2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_60M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/3-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_60M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "videos/3w-2n.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/3-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/3w-1n.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/3-1e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_VILLAGE),
        new TransitionItem(LEVEL_100M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovevillage/3e-1s.mp4"
    ),
];

const mods = [
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/1w-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/1n-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/1e-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/1s-h2.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/2w-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/2n-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/2e-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_60M, LEVEL_60M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/2s-h2.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/3w-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/3w-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/3e-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_VILLAGE, MODE_HOUSES),
        new TransitionItem(LEVEL_100M, LEVEL_100M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/3e-h2.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h2-2w-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h2-2n-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h2-2e-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h2-2s-1w.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/rf_2w-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/rf_2n-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/rf_2e-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/rf_2s-1w.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h1-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h2-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h3-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h4-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h5-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h6-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h7-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h8-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_VILLAGE),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/between/h9-1w.mp4"
    ),
];

const loopsHouses = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9.mp4"
    ),
];
const transitionsHouses = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(0, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/1-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/2-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(2, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/3-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(3, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/4-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(4, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/5-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(5, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/6-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-8.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(6, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/7-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(7, 8),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/8-9.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 0),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-1.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 2),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-3.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 3),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-4.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 4),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-5.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 5),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-6.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 6),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-7.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(8, 7),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "wallmoving/9-8.mp4"
    ),
];
const modsHouses = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_NONE, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2_in_h2-1n.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1n_out_h2.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2w-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2n-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2e-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2s-h2.mp4"
    ),



    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2w-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2n-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2e-h2.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_10M, LEVEL_10M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_NONE),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2s-h2.mp4"
    ),
];
const plotWalking = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2w-plotwalking_w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2n-plotwalking_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2e-plotwalking_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/h2-2s-plotwalking_w.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2w-plotwalking_w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2n-plotwalking_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2e-plotwalking_e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/rf_2s-plotwalking_w.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/plotwalking_w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/plotwalking-e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/plotwalking-e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/plotwalking_w.mp4"
    ),

    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/plotwalking_e-h2-2n.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/plotwalking_w-h2-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/plotwalking_w-h2-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_PLOT, HOUSE_MODE_PLOT),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "transitions/abovehouses/plotwalking_e-h2-2n.mp4"
    ),
];

const loopsHouse2M = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1n.mp4"
    ),
];
const directionsHouse2M = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1es.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1se.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
    "abovethehouses/h2/h2-1sw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1ws.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1en.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1ne.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1nw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_2M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1wn.mp4"
    ),
];
const levelsHouse2M = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1-2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1-2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-1-2n.mp4"
    ),
];

const loopsHouse5M = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2n.mp4"
    ),
];
const directionsHouse5M = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2es.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2se.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2sw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2ws.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2en.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2ne.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2nw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2wn.mp4"
    ),
];
const levelsHouse5M = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_2M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2-1e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_2M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2-1s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_2M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_2M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/h2-2-1n.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-2e-rf_2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-2s-rf_2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-2w-rf_2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_5M, LEVEL_5M),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_360, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-2n-rf_2n.mp4"
    ),
];

const loopsHouseFloor2 = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2n.mp4"
    ),
];
const directionsHouseFloor2 = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2e-rf_2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2s-rf_2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2s-rf_2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2w-rf_2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2e-rf_2n.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2n-rf_2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2n-rf_2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2w-rf_2n.mp4"
    ),
];
const levelsHouseFloor2 = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_1F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2-1e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_1F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2-1s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_1F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2-1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_1F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2-1n.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2e-2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2s-2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2w-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_2F, LEVEL_2F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_360),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_2n-2n.mp4"
    ),
];

const loopsHouseFloor1 = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1n.mp4"
    ),
];
const directionsHouseFloor1 = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1es.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1se.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1sw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1ws.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1en.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1ne.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1nw.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_1F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1wn.mp4"
    ),
];
const levelsHouseFloor1 = [
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_2F),
        new TransitionItem(DIRECTION_EAST, DIRECTION_EAST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1-2e.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_2F),
        new TransitionItem(DIRECTION_SOUTH, DIRECTION_SOUTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1-2s.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_2F),
        new TransitionItem(DIRECTION_WEST, DIRECTION_WEST),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1-2w.mp4"
    ),
    new Transition(
        new TransitionItem(MODE_HOUSES, MODE_HOUSES),
        new TransitionItem(LEVEL_1F, LEVEL_2F),
        new TransitionItem(DIRECTION_NORTH, DIRECTION_NORTH),
        new TransitionItem(1, 1),
        new TransitionItem(HOUSE_MODE_ROOF, HOUSE_MODE_ROOF),
        new TransitionItem(TIME_MORNING, TIME_MORNING),
        "abovethehouses/h2/roofremoval/h2-rf_1-2n.mp4"
    ),
];

const transitions: Transition[] = [
    ...loops10M,
    ...directions10M,
    ...levels10M,

    ...loops60M,
    ...directions60M,
    ...levels60M,

    ...loops100M,
    ...time100M,
    ...levels100M,

    ...mods,

    ...loopsHouses,
    ...transitionsHouses,
    ...modsHouses,
    ...plotWalking,

    ...loopsHouse2M,
    ...directionsHouse2M,
    ...levelsHouse2M,

    ...loopsHouse5M,
    ...directionsHouse5M,
    ...levelsHouse5M,

    ...loopsHouseFloor2,
    ...directionsHouseFloor2,
    ...levelsHouseFloor2,

    ...loopsHouseFloor1,
    ...directionsHouseFloor1,
    ...levelsHouseFloor1,
];

export default transitions;